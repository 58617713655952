import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import './Circulation.css';

const Circulation = ({ data }) => {
  return (
    <div className="circulation-container w-100 bg-white p-4 br-2">
      <p className="">{data?.productName}</p>
      <div className="circulation-container__caption">
        <div className="circulation-container__informations">
          <div className="circulation-container__information">
            <span>Tur nömrəsi</span>
            <span>{data?.code}</span>
          </div>
          <div className="circulation-container__information">
            <span>Hovuz məbləği</span>
            <span
              className={
                !data?.possibleSeeTotalAmount
                  ? 'circulation-container__information-blur'
                  : ''
              }
            >
              {data?.totalAmount} AZN
            </span>
          </div>
          <div className="circulation-container__information">
            <span>Mərcin bitmə tarixi</span>
            <span className="circulation-container__information-black">
              {data?.expiredDate &&
                format(new Date(data.expiredDate), 'dd-MM-yyyy HH:mm')}
            </span>
          </div>
          <div className="circulation-container__information">
            <span>Turun bitmə tarixi</span>
            <span className="circulation-container__information-black">
              {data?.resultedDate &&
                format(new Date(data.resultedDate), 'dd-MM-yyyy HH:mm')}
            </span>
          </div>
          <div className="circulation-container__information">
            <span>Turun statusu</span>
            {data?.code === '000030' &&
            data?.resultedDate === '2024-11-26T12:00:00' ? (
              <span style={{ color: '#FFB22C', fontSize: '12px' }}>
                Texniki səbəblərdən tur ləğv edilmişdir.
              </span>
            ) : (
              <span
                style={{
                  color: data?.canBePlayed
                    ? '#24A3FF'
                    : !data?.canBePlayed && !data?.isResulted
                    ? '#FFB22C'
                    : 'red',
                }}
              >
                {data?.canBePlayed
                  ? 'Aktiv'
                  : !data?.canBePlayed && !data?.isResulted
                  ? 'Nəticə gözlənilir'
                  : 'Bitib'}
              </span>
            )}
          </div>
        </div>
        <div className="circulation-container__buttons">
          <Link to={`/circulations/${data?.id}/tickets`}>Biletlər</Link>
        </div>
      </div>
    </div>
  );
};

export default Circulation;
